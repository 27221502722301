import { useScrollSlideStyles } from 'hooks/useUI/useScrollSlideStyles';
import React from 'react';

export type ScrollSlideAnimationProps = {
  yRange: [number, number];
} & ParentClassNameProp &
  ChildrenProp;

const ScrollAnimation: React.ComponentType<Pick<ScrollSlideAnimationProps, 'yRange' | 'children'>> =
  React.memo(({ children, yRange }) => {
    const { styles: scrollStyles } = useScrollSlideStyles({ yRange });

    return <div style={scrollStyles}>{children}</div>;
  });

ScrollAnimation.displayName = 'ScrollAnimation';

export const ScrollSlideAnimation: React.FC<ScrollSlideAnimationProps> = ({ yRange, children }) => (
  <div className="bg-size-100">
    <ScrollAnimation yRange={yRange}>{children}</ScrollAnimation>
  </div>
);

ScrollSlideAnimation.displayName = 'ScrollSlideAnimation';
