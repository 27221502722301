import { useLayoutEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';
import { truckMapConfig } from 'truckMapConfig';

type ScrollSlideProps = {
  yRange: [number, number];
};

/**
 * Hook for creating transform style object for scroll with viewport effect
 * @params ScrollSlideProps
 * @returns ScrollValues
 */
export const useScrollSlideStyles = ({ yRange }: ScrollSlideProps) => {
  const { y: scrollY } = useWindowScroll();

  // update scrollYClientSide on client side only to avoid mismatch with server side
  const [scrollYClientSide, setScrollY] = useState<number>(0);

  if (!truckMapConfig.isClientSideRender) {
    return { styles: {} };
  }

  const isWithinRange = scrollYClientSide > yRange[0] - 1 && scrollY < yRange[1] + 1;
  const isGreaterThan = scrollYClientSide > yRange[1];

  useLayoutEffect(() => {
    setScrollY(scrollY);
  }, [scrollY]);

  return {
    styles: {
      transform: isWithinRange
        ? `translateY(${scrollYClientSide - yRange[0]}px)`
        : isGreaterThan
        ? `translateY(${yRange[1] - yRange[0]}px)`
        : undefined
    }
  };
};
