import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const MarkerBeaconIcon = React.memo((props: GlyphProps) => (
  <Glyph width={82} height={82} {...props}>
    <circle
      opacity="0.08"
      r="24"
      transform="matrix(1 0 0 -1 41 41)"
      fill="#F7F8FA"
      stroke="#266EBC"
      strokeWidth="2"
    />
    <circle
      opacity="0.04"
      r="32"
      transform="matrix(1 0 0 -1 41 41)"
      stroke="#266EBC"
      strokeWidth="2"
    />
    <circle
      opacity="0.02"
      r="40"
      transform="matrix(1 0 0 -1 41 41)"
      stroke="#266EBC"
      strokeWidth="2"
    />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3037 72.1187L40.9999 42L67.696 72.1187C60.5229 78.2781 51.196 82 40.9999 82C30.8037 82 21.4768 78.2781 14.3037 72.1187Z"
      fill="url(#paint0_radial)"
    />
    <g filter="url(#filter0_d)">
      <circle r="16" transform="matrix(1 0 0 -1 41 41)" fill="white" />
    </g>
    <path
      d="M39.2111 47.4223L34.2777 37.5554C33.4648 35.9296 35.0668 34.15 36.7688 34.7883L40.2978 36.1117C40.7505 36.2814 41.2495 36.2814 41.7022 36.1117L45.2312 34.7883C46.9332 34.15 48.5352 35.9296 47.7223 37.5554L42.7889 47.4223C42.0518 48.8964 39.9482 48.8964 39.2111 47.4223Z"
      fill="#266EBC"
    />
    <defs>
      <filter
        id="filter0_d"
        x="21"
        y="23"
        width="40"
        height="40"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(41 54) rotate(90) scale(26 34.705)">
        <stop stopColor="#266EBC" />
        <stop offset="1" stopColor="#266EBC" stopOpacity="0" />
      </radialGradient>
    </defs>
  </Glyph>
));

MarkerBeaconIcon.displayName = 'MarkerBeaconIcon';

export { MarkerBeaconIcon };
