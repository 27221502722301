import { sendSMSLink } from 'clients/sms-link-client';
import { globalAlert } from 'components/common/Alert/AlertGlobalHotToast';
import { Button } from 'components/common/Button';
import { TextInputEntry } from 'components/contentful/TextInputEntry';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { makeStyles } from 'lib/makeStyles';
import { Controller, useForm } from 'react-hook-form';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';

type Props = {
  input: SerializedComponentItem;
  button: SerializedComponentItem;
};

const useStyles = makeStyles({
  root: ['flex justify-start items-start', 'mt-9'],
  inputContainer: ['w-full', 'mr-3'],
  input: 'w-full'
});

const phoneInputName = 'phone';

export const AppLinkForm = ({ input, button }: Props) => {
  const { handleSubmit, formState, resetField, control } = useForm();
  const { t } = useTranslation();
  const onSubmit = ({ phone }) =>
    sendSMSLink(phone)
      .then(() => {
        resetField(phoneInputName, { keepDirty: false, keepError: false, keepTouched: false });
        globalAlert({
          intent: 'success',
          message: t('SMS_APP_LINK_SUCCESS'),
          isSmall: true
        });
      })
      .catch(() => {
        globalAlert({
          intent: 'danger',
          message: t('SMS_APP_LINK_ERROR', { variables: { phone } }),
          isSmall: true
        });
      });
  const styles = useStyles();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
      <div className={styles.inputContainer}>
        <Controller
          render={({ field }) => (
            <TextInputEntry
              {...field}
              key={field.name}
              mask="+1 (999) 999-9999"
              className={styles.input}
              placeholder="+1 (___) ___-____"
              entry={input}
            />
          )}
          name={phoneInputName}
          control={control}
          rules={{ required: true }}
          defaultValue=""
        />
      </div>
      <Button isPrimary type="submit" disabled={formState.isSubmitting}>
        {button.primaryText}
      </Button>
    </form>
  );
};
