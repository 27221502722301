import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const RoutePlannerIcon = React.memo((props: GlyphProps) => (
  <Glyph width={22} height={22} {...props}>
    <path
      d="M5 1.75H17V0.25H5V1.75ZM20.25 5V17H21.75V5H20.25ZM17 20.25H5V21.75H17V20.25ZM1.75 17V5H0.25V17H1.75ZM5 20.25C3.20507 20.25 1.75 18.7949 1.75 17H0.25C0.25 19.6234 2.37665 21.75 5 21.75V20.25ZM20.25 17C20.25 18.7949 18.7949 20.25 17 20.25V21.75C19.6234 21.75 21.75 19.6234 21.75 17H20.25ZM17 1.75C18.7949 1.75 20.25 3.20507 20.25 5H21.75C21.75 2.37665 19.6234 0.25 17 0.25V1.75ZM5 0.25C2.37665 0.25 0.25 2.37665 0.25 5H1.75C1.75 3.20507 3.20507 1.75 5 1.75V0.25Z"
      fill="currentColor"
    />
    <path
      d="M1 17H8.10861C11.3575 17 12.7769 12.8961 10.2222 10.8889V10.8889C8.17848 9.28309 9.31398 6 11.9131 6H16C16.5523 6 17 5.55228 17 5V1"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </Glyph>
));

RoutePlannerIcon.displayName = 'RoutePlannerIcon';

export { RoutePlannerIcon };
