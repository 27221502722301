import { TextInput, TextInputProps } from 'components/common/Input';
import { Translation } from 'components/common/Translation';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';

export type TextInputEntryProps = TextInputProps & {
  entry: SerializedComponentItem;
  error?: string;
};

const useStyles = makeStyles({
  root: ['text-xs mt-2 ml-3 text-red-500'],
  srOnly: 'sr-only'
});

const TextInputEntry = React.forwardRef<HTMLInputElement, TextInputEntryProps>(
  ({ entry, error, ...rest }, ref) => {
    const styles = useStyles();
    return (
      <div>
        <label htmlFor={entry.id} className={styles.srOnly}>
          {entry.primaryText}
        </label>
        <TextInput {...rest} ref={ref} placeholder={entry.primaryText} />
        {error && (
          <p className={styles.root}>
            <Translation textKey="REQUIRED_FIELD" />
          </p>
        )}
      </div>
    );
  }
);

TextInputEntry.displayName = 'TextInputEntry';

export { TextInputEntry };
