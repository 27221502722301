import { PhoneAsset } from 'components/common/PhoneAsset';
import { BodyText, HeadingText } from 'components/common/Typography';
import { Asset } from 'components/contentful/Asset';
import { RuntimeFeatureEnabled } from 'components/FeatureEnabled';
import { Container } from 'components/layout/Container';
import { useHomepageFeatures } from 'hooks/useHomepageFeatures';
import { makeStyles } from 'lib/makeStyles';
import dynamic from 'next/dynamic';
import React from 'react';
import { UIComponentProps } from 'types/contentful';

import { AppLinkForm } from './AppLinkForm';
import { Features } from './Features';

type HomepageHeroProps = UIComponentProps;

const AppStoreReviews = dynamic(
  () => import('components/AppStoreReviews').then((mod) => mod.AppStoreReviews),
  { ssr: false }
);

const useStyles = makeStyles({
  assetOuterWrapper: ['relative bg-neutralBackground', 'pb-12 pt-64 sm:pt-72 md:pt-12 lg:pb-0'],
  assetInnerWrapper: ['absolute inset-0 overflow-hidden md:hidden', 'px-4 pt-16 md:pt-4'],
  gradient: ['absolute inset-0', 'bg-gradient-to-t from-neutralBackground via-neutralBackground'],
  asset: ['w-full h-auto object-contain'],
  contentOuterWrapper: ['relative flex flex-row z-10'],
  contentInnerWrapper: [
    'flex flex-col justify-center ',
    'px-1 md:px-4 w-full md:w-2/3',
    'bg-image-homepage-section-hero-left bg-size-100 bg-no-repeat bg-pos-20',
    'border-r-2 border-neutralBorder'
  ],
  headingWrapper: ['max-w-lg'],
  heading: ['block sm:mt-5'],
  subHeading: ['mt-3']
});

const HomepageHero = React.memo(({ entry, items }: HomepageHeroProps) => {
  const featureBullets = items.get('FeatureBullet');
  const [state] = useHomepageFeatures();
  const featureImageItem = featureBullets.find((bullet) => bullet.id === state);
  const [imageItem] = items.get('Image');
  const inputs = items.get('TextInput');
  const buttons = items.get('Button');
  const styles = useStyles();

  const [heading, subheading] = entry.richTextSections;

  return (
    <>
      <div className={styles.assetOuterWrapper}>
        <Container>
          <div className={styles.assetInnerWrapper}>
            <Asset entry={imageItem} className={styles.asset} />
            <div className={styles.gradient} />
            <div style={{ bottom: 60 }} className={styles.gradient} />
          </div>
          <div className={styles.contentOuterWrapper}>
            <div className={styles.contentInnerWrapper}>
              <div className={styles.headingWrapper}>
                <HeadingText size="LG" className={styles.heading}>
                  {heading.primaryText}
                </HeadingText>
                <BodyText large className={styles.subHeading}>
                  {subheading.primaryText}
                </BodyText>
                <RuntimeFeatureEnabled name="smsAppLink">
                  <AppLinkForm input={inputs[0]} button={buttons[0]} />
                </RuntimeFeatureEnabled>
              </div>
              <AppStoreReviews />
            </div>
            <PhoneAsset entry={featureImageItem ?? imageItem} yRange={[0, 550]} />
          </div>
        </Container>
      </div>
      <Features entries={featureBullets} image={imageItem} />
    </>
  );
});

HomepageHero.displayName = 'HomepageHero';

export { HomepageHero };
