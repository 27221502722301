import { Asset } from 'components/contentful/Asset';
import { Container } from 'components/layout/Container';
import { useHomepageFeatures } from 'hooks/useHomepageFeatures';
import { MarkerBeaconIcon } from 'icons/MarkerBeaconIcon';
import { makeStyles } from 'lib/makeStyles';
import React, { useEffect } from 'react';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';

import { Feature } from './Feature';

type Props = {
  entries: SerializedComponentItem[];
  image: SerializedComponentItem;
};

const useStyles = makeStyles({
  outerWrapper: ['relative'],
  assetOuterWrapper: ['absolute inset-0 md:hidden pt-12 px-4 overflow-hidden'],
  asset: ['w-full object-contain object-top'],
  gradient: ['absolute inset-0 bg-gradient-to-t from-neutralBackground via-neutralBackground'],
  container: ['relative pt-24 pb-14 md:py-24'],
  containerInnerWrapper: ['bg-image-left-border bg-repeat-y bg-pos-20'],
  containerBackground: [
    'w-full relative md:w-2/3',
    'bg-image-homepage-section-features bg-no-repeat bg-right-bottom'
  ],
  markerBeaconIcon: ['absolute text-6xl -right-7 top-20 hidden md:block'],
  entriesOuterWrapper: ['relative list-none md:pr-12 mt-24 md:mt-0'],
  containerInner: 'px-1 md:px-4'
});

const Features = ({ entries, image }: Props) => {
  const [selected, setSelected] = useHomepageFeatures(entries[0].id);
  const styles = useStyles();

  useEffect(() => {
    if (!selected) {
      setSelected();
    }
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.assetOuterWrapper}>
        <Asset entry={image} className={styles.asset} />
        <div className={styles.gradient} />
      </div>
      <Container className={styles.container}>
        <div className={styles.containerInner}>
          <div className={styles.containerInnerWrapper}>
            <div className={styles.containerBackground}>
              <MarkerBeaconIcon className={styles.markerBeaconIcon} />
              <div className={styles.entriesOuterWrapper}>
                {entries.map((entry) => (
                  <Feature entry={entry} key={entry.id} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export { Features };
